<template>
  <div>
    <div class="container" style="height: 85vh">
      <div
        class="row px-3 mt-2 py-3 d-flex justify-content-between align-items-center"
      >
        <button class="btn d-flex align-items-center" @click="closeGallery">
          <i
            class="fa fa-arrow-left mr-3 headTitle"
            style="font-size: 16px"
            aria-hidden="true"
          ></i>
          <p class="headTitle m-0">Gallery</p>
        </button>
        <div>
          <button
            @click="closeGallery"
            class="outline-none outline-none border-0 px-2 py-1 text-white"
            style="cusrsor: pointer; border-radius: 6px; background: #b3afb6"
          >
            Cancel
          </button>
          <button
            @click="selectImageDone(imageSrc)"
            class="outline-none outline-none border-0 px-3 py-1 text-white mx-2"
            :style="
              imageSrc && selectedImage
                ? 'background:#4D1B7E'
                : 'background:#4D1B7Ea6; pointer-events: none;'
            "
            style="cusrsor: pointer; border-radius: 6px"
          >
            Save
          </button>
        </div>
      </div>
      <div class="row px-3 mt-2">
        <div class="col-8">
          <div class="form-group subheading d-flex align-items-center">
            <input
              type="text"
              id="imgInput"
              v-model="imageLink"
              placeholder="Place image Link "
              class="form-control-sm"
              style="
                width: 95%;
                background: transparent;
                color: #4d4950;
                border: 1px solid #b3afb6;
              "
            />
            <span
              v-if="imageLink.length > 0"
              @click="imageLink = ''"
              class="ml-2"
              id="add_product_cancel_button"
              style="color: red; cursor: pointer"
            >
              <i class="fa fa-times float-right"></i>
            </span>
          </div>
        </div>
        <div class="col-4">
          <button
            @click="uploadImageLink"
            class="outline-none outline-none border-0 px-2 py-1 text-white w-100"
            :class="spinner ? 'py-2' : ''"
            :style="
              imageLink.length > 0
                ? 'background:#4D1B7E'
                : 'background:#4D1B7Ea6; pointer-events: none;'
            "
            style="cusrsor: pointer; border-radius: 6px"
          >
            <span v-if="!spinner">Add image</span>
            <div v-else class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
      <div
        class="containerImages"
        style="height: 70vh; overflow-x: hidden; overflow-y: scroll"
      >
        <div v-if="isLoading">
          <LoaderComp />
        </div>
        <div v-else>
          <div class="row p-3" style="">
            <div
              class="col-6 mb-2"
              @dragover.prevent
              @drop.prevent
              id="dragDiv"
            >
              <input
                type="file"
                accept="image/png,image/gif,image/jpeg,image/svg+xml"
                style="display: none"
                ref="fileInput"
                multiple
                @change="uploadFile"
              />
              <div
                v-show="!uploadingStatus"
                @dragenter.prevent="setActive"
                @dragover.prevent="setActive"
                @dragleave.prevent="setInactive"
                @drop="dragFile"
                class="upload-div d-flex flex-column justify-content-center align-items-center"
                @click="$refs.fileInput.click()"
              >
                <i class="fa fa-cloud-upload fa-4x" aria-hidden="true"></i>
                <h4 class="font-weight-bold">
                  {{ dragActive ? "Drop to upload" : "Upload or Drop" }}
                </h4>
              </div>
            </div>
            <div
              v-show="fileList.length"
              class="col-6 mb-2"
              v-for="(file, fileIndex) in fileList"
              :key="fileIndex"
            >
              <div class="image-div" @click="toggleSelectImage(file.imageLink)">
                <LazyImage
                  :class="
                    imageSrc == file.imageLink
                      ? 'selected-image'
                      : 'other-image'
                  "
                  :src="ImageSrc(file.imageLink)"
                  alt=""
                />
                <span
                  v-show="imageSrc == file.imageLink"
                  class="Polaris-Icon_yj27d"
                >
                  <svg
                    viewBox="0 0 20 20"
                    class="selected-svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path
                      fill="white"
                      d="m8.315 13.859-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.436.436 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComp from "../../customize/component/LoaderComp.vue";
import axios from "axios";
export default {
  components: {
    LoaderComp,
  },
  props: ["Img", "productImages", "galleryMode", "selectedProductImageIndex"],
  data() {
    return {
      File: [],
      fileList: [],
      imageSrc: "",
      uploadingStatus: false,
      uploadingPercent: "89%",
      isLoading: false,
      imageLink: "",
      spinner: false,
      selectedImage: "",

      dragActive: false,
    };
  },
  methods: {
    OpenImageGallery() {
      if (this.Img) {
        this.selectedImage = this.Img;
      }
      this.fileList = this.productImages;
      //  this.imageSrc = this.selectedImage.substring(1);
      this.imageSrc = this.selectedImage;
    },
    ImageSrc(img) {
      return img.startsWith("upload") ? `/${img}` : img;
    },
    toggleSelectImage(path) {
      if (this.imageSrc == path) this.imageSrc = "";
      else this.imageSrc = path;
    },
    getImages() {
      axios.get("/images/list").then((response) => {
        this.fileList = response.data.reverse();
      });
    },
    setActive() {
      this.dragActive = true;
    },
    setInactive() {
      this.dragActive = false;
    },
    uploadFile(e) {
      // let  = e.target.files;
      this.uploadOnServer(e.target.files);
    },
    dragFile(e) {
      // this.File = e.dataTransfer.files;
      this.uploadOnServer(e.dataTransfer.files);
    },
    async uploadImageLink() {
      this.spinner = true;

      try {
        let data = {
          image_link: this.imageLink,
        };
        const response = await axios.post(`/saveImageLink`, data);

        if (response.status == 200) {
          this.imageLink = "";
          this.getImages();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.spinner = false;
        this.dragActive = false;
      }
    },
    uploadOnServer(files) {
      this.isLoading = true;
      let formData = new FormData();

      for (const file of files) {
        if (
          file.type == "image/jpeg" ||
          file.type == "image/png" ||
          file.type == "image/gif" ||
          file.type == "image/svg+xml"
        )
          formData.append("images[]", file);
      }
      axios
        .post("/upload/images", formData, {
          onUploadProgress: (uploadEvent) => {
            this.uploadingPercent =
              (uploadEvent.loaded / uploadEvent.total) * 100 + "%";
          },
        })
        .then((resp) => {
          // this.fileList.unshift.apply(this.fileList,resp.data)
          const newArr = [...this.fileList];
          for (let entry of resp.data) {
            newArr.unshift(entry);
          }

          this.fileList = newArr;
          this.isLoading = false;

          this.dragActive = false;
        });
    },

    selectImageDone(imageSrc) {
      if (this.galleryMode == "ProductView") {
        if (this.selectedProductImageIndex !== null) {
          this.$emit("changeProductOtherImage", imageSrc);
        } else {
          this.$emit("changeProductImage", imageSrc);
        }
      } else {
        this.$emit("selectedImageCallback", imageSrc);
      }
      this.closeGallery();
    },
    closeGallery() {
      this.$emit("closeGallery", this.galleryMode);
      this.selectedImage = "";

      this.fileList = [];
      this.imageSrc = "";
    },
  },
  mounted() {
    this.OpenImageGallery();
  },
};
</script>

<style>
.headTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #4b494e;
}
.containerImages {
  padding: 0 10%;
}
.modal-btn:hover {
  color: #ffffff;
}
.upload-div {
  min-height: 391px;
  height: 100%;
  text-align: center;
  background: #e0dee280;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.upload-div i,
.upload-div h4 {
  color: #6d6b6d;
}
.upload-div:hover {
  background: rgba(77, 27, 126, 0.5);
}
.upload-div:hover i,
.upload-div:hover h4 {
  color: #ffffff;
}
.image-div {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}
.image-div img {
  position: absolute;
}
.other-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.selected-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #ffb201;
  cursor: pointer;
}
.selected-svg {
  height: 20px;
  width: 20px;
  background: #ffb201;
  border-radius: 100px;
  color: white;
  position: absolute;
  bottom: 6px;
  right: 10px;
}
.percent-text {
  position: absolute;
  font-size: 16px;
  bottom: 12px;
  font-weight: bold;
}
#imgInput:focus {
  outline: none !important;
}
</style>
